import Big from 'big.js';

/**
 * See if the given search (as a partial record) exists within the
 * provided records array
 *
 * @param {*} records
 * @param {*} search
 * @return True if the search matches a record in the given array
 */
const existsRecord = (records, search) =>
  records.findIndex((record) => recordsPartiallyEqual(record, search)) > -1;

/**
 * Return a record from the provided records array that matches
 * the provided search criteria
 *
 * @param {*} records
 * @param {*} search
 * @return A record matching the search criteria, undefined otherwise
 */
const findRecord = (records, search) =>
  records.find((record) => recordsPartiallyEqual(record, search));

/**
 * Filter records from the given records array which match the
 * search criteria
 *
 * @param {*} records
 * @param {*} search
 * @return The records filtered accoring to criteria
 */
const includeRecords = (records, search) =>
  records.filter((record) => recordsPartiallyEqual(record, search));

/**
 * Filter records from the given records array which do not match the
 * search criteria
 *
 * @param {*} records
 * @param {*} search
 * @return The records filtered accoring to criteria
 */
const excludeRecords = (records, search) =>
  records.filter((record) => !recordsPartiallyEqual(record, search));

/**
 * Takes a record with a possible Big values and convert values to PODs.
 * Used before sending records across the service network boundary, since
 * the Big will not be preserved.
 *
 * @param {*} record The record containing non PODs to flatten.
 * @return A record containing only PODs
 */
const flattenRecord = (record) => ({
  ...record,
  quantity: flattenQuantity(record.quantity),
});

/**
 * Convert Big.js value'd quantity records to pure JS doubles.
 *
 * @param {*} records
 * @return The quantity records containing pure JS doubles
 */
const flattenRecords = (records) => records.map(flattenRecord);

/**
 * The incoming record quantity value is negative, since it is pending,
 * however we require a positive amount to perform proper arithmetic.
 *
 * @param {*} records
 * @return The complement record quantity values
 */
const complementRecords = (records) =>
  records.map((record) => ({
    ...record,
    quantity: {
      ...record.quantity,
      value: Big(record.quantity.value).times(-1),
    },
  }));

const sumRecords = (records) => {
  const quantity = records.reduce(
    (acc, cur) => ({
      measure: cur.quantity.measure,
      value: acc.value.plus(cur.quantity.value),
    }),
    { value: Big(0), measure: null }
  );

  return { ...quantity, value: parseFloat(quantity.value.toString()) };
};

const factorizeRecords = (records, factor) =>
  records.map((record) => ({
    ...record,
    quantity: {
      ...record.quantity,
      value: Big(record.quantity.value).times(factor),
    },
  }));

/**
 * Given two records set, merge them by grouping according to
 * matching refs as well as matching quantity measures.
 * Note that we must clone since expansion will preserve the
 * original references and therefore cause problems.
 *
 * @param {*} lhs An array of records to merge
 * @param {*} rhs An array of records to merge
 * @return The merged records grouped by ref and quantity measure.
 */
const mergeRecords = (lhs, rhs) =>
  JSON.parse(JSON.stringify([...lhs, ...rhs])).reduce((acc, record) => {
    const measure = record.quantity.measure;
    const ref = record.ref;

    const i = acc.findIndex((s) =>
      recordsPartiallyEqual(s, { ref, quantity: { measure } })
    );

    if (i === -1) acc.push(record);
    else acc[i].quantity.value += record.quantity.value;

    return acc;
  }, []);

/**
 * Transform an array of records to an array of refs. Note that
 * there is no ref uniqueness computation done. here.
 *
 * @param {*} records
 * @return An array of refs
 */
const recordsToRefs = (records) => records.map((record) => record.ref).flat();

/**
 * Return a ref from the provided refs array that matches the search criteria
 *
 * @param {*} refs
 * @param {*} search
 * @return A ref matching the search criteria, undefined otherwise
 */
const findRef = (refs, search) =>
  refs.find((ref) => refsPartiallyEqual(ref, search));

/**
 * Compare two ref objects, returning true if both id and typeHandle match.
 *
 * @param {*} a
 * @param {*} b
 * @return True if both refs properties are equal eachother
 */
const refsEqual = (a, b) => a.id === b.id && a.typeHandle === b.typeHandle;

/**
 * Compare two ref objects, returning true only if some partially defined
 * subproperties are equal.
 *
 * @param {*} a
 * @param {*} b
 * @return True if both refs properties are equal eachother
 */
const refsPartiallyEqual = (a, b) => {
  if (!(a.id && b.id) && !(a.typeHandle && b.typeHandle))
    throw new Error('Nothing to compare!');

  if (a.id && b.id && a.id !== b.id) return false;
  if (a.typeHandle && b.typeHandle && a.typeHandle !== b.typeHandle)
    return false;

  return true;
};

const uniqueRefs = (refs) =>
  refs.reduce((acc, cur) => {
    const i = acc.findIndex((ref) => refsEqual(ref, cur));
    if (i === -1) acc.push(cur);
    return acc;
  }, []);

/**
 * Given an two input ref arrays, return true if each ref in
 * the seek array is found within the source array.
 *
 * @param {*} seek The array of refs to search for
 * @param {*} source The candidate array of refs to search
 * @return True if each ref in source is found in seek
 */
const containsRef = (seek, source) => {
  for (const lhs of seek) {
    let found = false;
    for (const rhs of source) {
      if (refsEqual(lhs, rhs)) {
        found = true;
        break;
      }
    }

    if (!found) return false;
  }

  return true;
};

/**
 * Compare two measure objects, returning true if both
 * the asset and unit handles are equal
 *
 * @param {*} a
 * @param {*} b
 * @return True if both measure properties are equal to eachother
 */
const measuresEqual = (a, b) =>
  a.unitHandle === b.unitHandle && a.assetHandle === b.assetHandle;

const quantitiesEqual = (a, b) =>
  measuresEqual(a.measure, b.measure) && Big(a.value).eq(b.value);

const pricesEqual = (a, b) =>
  measuresEqual(a.base, b.base) &&
  measuresEqual(a.quote, b.quote) &&
  Big(a.value).eq(b.value);

const quantitiesPartiallyEqual = (a, b) => {
  if (!(a.value && b.value) && !(a.measure && b.measure))
    throw new Error('Nothing to compare!');

  if (a.value && b.value && !Big(a.value).eq(b.value)) return false;
  if (a.measure && b.measure && !measuresEqual(a.measure, b.measure))
    return false;
  return true;
};

const recordsPartiallyEqual = (a, b) => {
  const haveQuantities = a.quantity && b.quantity;
  const haveRefs = a.ref && b.ref;

  if (!haveQuantities && !haveRefs) throw new Error('Nothing to compare!');

  if (haveQuantities && !quantitiesPartiallyEqual(a.quantity, b.quantity))
    return false;

  if (haveRefs && !refsPartiallyEqual(a.ref, b.ref)) return false;

  return true;
};

const locationsEqual = (a, b) =>
  a.latitude === b.latitude && a.longitude === b.longitude;

const sortQuantities = (a, b) => {
  if (a.measure.assetHandle > b.measure.assetHandle) return -1;
  if (a.measure.assetHandle < b.measure.assetHandle) return 1;
  if (a.measure.unitHandle > b.measure.unitHandle) return 1;
  if (a.measure.unitHandle < b.measure.unitHandle) return -1;
  return 0;
};

const sortRecords = (a, b) => sortQuantities(a.quantity, b.quantity);

/**
 * Nasty assumption here that x is always a datetime, else would need
 * to include the dimensions as well in order to check the comparison
 *
 * @todo Integrate the dimensions here
 * @param {*} a
 * @param {*} b
 * @return -1 if b < a, 1 if b > 1, 0 if equal
 */
const sortVectors = (a, b) => {
  if (b.vector.x < a.vector.x) return -1;
  if (b.vector.x > a.vector.x) return 1;
  return 0;
};

/**
 * Takes a quantity with a possible Big value and convert value to POD.
 * Used before sending a quantity across the network boundary, since
 * the Big will not be preserved.
 *
 * @param {Quantity} quantity The quantity containing non POD value to flatten
 * @return A quantity containing value as POD
 */
const flattenQuantity = (quantity) => ({
  ...quantity,
  value: parseFloat(quantity.value.toString()),
});

/**
 * Takes a price with a possible Big value and convert value to POD.
 * Used before sending a price across the network boundary, since
 * the Big will not be preserved.
 *
 * @param {Price} price The price containing non POD value to flatten
 * @return A price containing value as POD
 */
const flattenPrice = (price) => ({
  ...price,
  value: parseFloat(price.value.toString()),
});

const arrayifyRef = ({ id, typeHandle }) => [id, typeHandle];

const arrayifyQuantity = ({ measure, value }) => [
  measure.assetHandle,
  measure.unitHandle,
  Big(value).toString(),
];

const arrayifyRecord = (record) => [
  ...arrayifyQuantity(record.quantity),
  ...arrayifyRef(record.ref),
];

const arraysEqual = (a, b) => {
  if (a.length !== b.length) return false;
  else {
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) return false;
    }

    return true;
  }
};

const flattenFlags = (message) => {
  const flags = message.flagsMap.reduce(
    (acc, cur) => ({ ...acc, [cur[0]]: cur[1] }),
    {}
  );

  delete message.flagsMap;

  return { ...message, flags };
};

const getDateRange = (fields) => {
  return (message) => {
    for (const field of Object.keys(fields)) {
      const dateRange = fields[field];
      if (!dateRange) continue;

      const value = message[field];
      if (dateRange.beginAt && !value.greaterThanOrEqual(dateRange.beginAt))
        return false;
      if (dateRange.endAt && !value.lessThanOrEqual(dateRange.endAt))
        return false;
    }

    return true;
  };
};

const flattenRef = (ref) =>
  ref.reduce((acc, { id, typeHandle }) => ({ ...acc, [typeHandle]: id }), {});

const aggregateQuantities = (
  balances,
  fieldName,
  typeHandle,
  balancePermissions = {}
) =>
  balances
    .filter((balance) =>
      typeHandle ? balance.typeHandle === typeHandle : true
    )
    .filter((balance) =>
      Object.keys(balancePermissions).length
        ? balance.source.ref.typeHandle in balancePermissions
        : true
    )
    .reduce((acc, cur) => {
      for (const quantity of cur[fieldName]) {
        const i = acc.findIndex((a) =>
          measuresEqual(a.measure, quantity.measure)
        );

        if (i === -1)
          acc.push({ measure: quantity.measure, value: Big(quantity.value) });
        else acc[i].value = acc[i].value.plus(quantity.value);
      }

      return acc;
    }, [])
    .sort(sortQuantities);

const encodeRef = (ref) => Buffer.from(JSON.stringify(ref)).toString('base64');

const decodeRef = (xauth) =>
  JSON.parse(Buffer.from(xauth, 'base64').toString('ascii'));

const sortMenus = (a, b) => {
  if (a.title > b.title) return 1;
  if (a.title < b.title) return -1;
  return 0;
};

const sortEmailChannels = (lhs, rhs) => {
  if (lhs.createdAt.toNumber() < rhs.createdAt.toNumber()) return 1;
  if (lhs.createdAt.toNumber() > rhs.createdAt.toNumber()) return -1;
  return 0;
};

export {
  aggregateQuantities,
  arrayifyRecord,
  arrayifyRef,
  arraysEqual,
  complementRecords,
  containsRef,
  decodeRef,
  encodeRef,
  excludeRecords,
  existsRecord,
  factorizeRecords,
  findRecord,
  findRef,
  flattenFlags,
  flattenPrice,
  flattenQuantity,
  flattenRecord,
  flattenRecords,
  flattenRef,
  getDateRange,
  includeRecords,
  locationsEqual,
  measuresEqual,
  mergeRecords,
  pricesEqual,
  quantitiesEqual,
  recordsToRefs,
  refsEqual,
  sortEmailChannels,
  sortMenus,
  sortQuantities,
  sortRecords,
  sortVectors,
  sumRecords,
  uniqueRefs,
};
